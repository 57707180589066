<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="40%"
    custom-class="invite-dialog"
    v-on="$listeners">
    <base-title
      slot="title"
      text="Invite User">
    </base-title>
    <div class="invite-container">
      <el-form
        class="el-form-item-left-lable"
        ref="form"
        :rules="rules"
        label-position="right"
        label-width="80px"
        :model="formData">
        <el-form-item
          :label="$t('email')"
          prop="email"
          required>
          <el-input v-model="formData.email"></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('team')"
          prop="teamId"
          required>
          <el-select
            style="width: 100%"
            v-model="formData.teamId">
            <el-option
              v-for="item in teamOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('role')"
          prop="roleId"
          required>
          <el-select
            style="width: 100%"
            v-model="formData.roleId">
            <el-option
              v-for="item in roleOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button
        size="small"
        @click="dialogVisible = false">
        {{ $t('cancel') }}
      </el-button>
      <el-button
        :loading="loading"
        type="primary"
        size="small"
        @click="submitInvite">
        {{ $t('invite') }}
      </el-button>
  </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'InviteMember',
  props: {
    companyId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      dialogVisible: false,
      formData: {
        email: '',
        teamId: '',
        roleId: ''
      },
      rules: {
        email: [
          { type: 'email', required: true, message: this.$t('emailFormatError'), trigger: 'blur' }
        ]
      },
      teamOptions: [],
      roleOptions: [],
      loading: false
    }
  },
  created () {
    this.getTeamByCompany()
    this.getRoleByCompany()
  },
  mounted () {
    this.dialogVisible = true
  },
  methods: {
    getTeamByCompany () {
      /**
       * 根据机构获取团队列表
       */
      this.$request.get({
        url: `${this.$apis.teamBaseUrl}?pageNumber=1&pageSize=99999&organizationId=${this.companyId}`,
      }).then(res => {
        if (res?.code === 1000) {
          const teams = res.data.records || []
          teams.forEach(item => {
            if (item.type !== 0) {
              this.teamOptions.push(
                {
                  value: item.id,
                  label: item.name
                }
              )
            }
          })
        }
      })
    },

    getRoleByCompany () {
      /**
       * 根据机构获取角色列表
       */
      this.$request.get({
        url: `${this.$apis.roleBaseUrl}?pageNumber=1&pageSize=99999&organizationId=${this.companyId}`
      }).then(res => {
        if (res?.code === 1000) {
          const roles = res.data.records
          const roleOptions = roles.map(item => {
            return {
              value: item.id,
              label: item.name,
              code: item.code
            }
          })
          const excludeRoleCodes = [this.$roleCode.systemAdmin, this.$roleCode.ship]
          // 过滤掉内置的管理员角色
          this.roleOptions = roleOptions.filter(item => item.code === null || !excludeRoleCodes.includes(item.code))
        }
      })
    },

    submitInvite () {
      /**
       * 提交邀请
       */
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          this.$request.post({
            url: this.$apis.inviteUser,
            data: {
              orgId: this.companyId,
              ...this.formData
            }
          }).then(res => {
            if (res?.code === 1000) {
              this.dialogVisible = false
              this.$message.success(this.$t('invitedMailSentSuccess'))
            }
          }).finally(() => {
            this.loading = false
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.invite-dialog {
  .invite-container {
    padding: 20px;
  }
}
</style>
